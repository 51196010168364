.container-sidebar {
  background-color: #f6f6f6;
  min-height: 100vh;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.nav-link.active {
  text-decoration: underline;
}

.no-hover:hover {
  color: #141413 !important;
}

.sidebar-hamburger {
  font-size: 1.5rem;
  color: #141413;
}

.sidebar-icon-link {
  width: 40px;
}

.sidebar-icon-link:hover .sidebar-icon-container {
  background-color: #e1e1e1;
}

.sidebar-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.sidebar-icon {
  width: 80%;
}

.nav-link.active .sidebar-icon-text div div {
  text-decoration: underline;
}

.nav-link.active .sidebar-icon-container {
  background-color: #e1e1e1;
}/*# sourceMappingURL=Sidebar.css.map */