.filter-box-single {
  background-color: #efefef;
  border-radius: 4px;
  z-index: 10;
  padding: 1rem;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  gap: 2rem;
  align-items: center;
}/*# sourceMappingURL=FilterBar.css.map */