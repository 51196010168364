@use "../../styles/variables" as *;

@media (min-width: 992px) {
  .split {
    border-left: 1px solid $line-grey;
  }

  .split-left-col {
    padding-right: 24px !important;
  }

  .split-right-col {
    padding-left: 24px !important;
  }
}
