@use "../../styles/variables" as *;

.custom-tooltip-container {
  background-color: rgba($color: $white, $alpha: 0.96);
  border: 1px solid $line-grey;
}

.custom-tooltip-text {
  text-wrap: nowrap;
}

.donut-left-content {
  font-size: 32px;
  font-weight: bold;
}

.simple-bar-container {
  background-color: $chart-grey;
  border-radius: 99px;
  width: 100%;
  min-width: 80px;
  height: 16px;
}

.simple-bar-container.big-bar {
  height: 24px;
}

.simple-bar-container.small {
  width: 50%;
}

.simple-bar-content {
  border-radius: 99px;
  height: 100%;
  transition: ease-in-out 0.5s !important;
}

.big-font {
  font-size: 24px;
  font-weight: bold;
}

.fixed-number-space {
  min-width: 90px;
}

.pie-chart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.legend-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  margin-top: 2rem;
}
