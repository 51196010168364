@use "../../styles/variables" as *;

.switcher-container {
  width: fit-content;
  border: 2px solid $primary-orange;
  border-radius: 99px;
  padding: 4px;
}

.switcher-button {
  background-color: $white;
  color: $primary-orange;
  cursor: pointer;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 99px;
  min-width: 180px;
  text-align: center;
  transition: all 0.3s;
}

.switcher-button:hover {
  background-color: rgba($primary-orange-light, 0.2);
}

.switcher-button.active {
  background-color: $primary-orange;
  color: $white;
}
