// use this file for color variable and such
$text-black: #141413;
$box-grey: #efefef;
$sidebar-grey: #f6f6f6;
$line-grey: #cccccc;
$chart-grey: #e1e1e1;
$white: #ffffff;

$primary-orange: #f28c00;
$primary-orange-light: #f8c580;
$primary-teal: #4fccaf;
$primary-green: #8cba2d;
$primary-yellow: #ffc61e;
$primary-dark-orange: #ff661c;
$primary-red: #d1282d;
$primary-grey: #333333;
$secondary-teal: #b9ebdf;
$secondary-green: #d4e9a6;
$secondary-orange: #ffba5f;
$secondary-pink: #ffc2a4;
$secondary-red: #ffa476;

$soft-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
  rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
$hover-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
