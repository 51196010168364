.kpi-container {
  padding: 20px 40px;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  min-height: 105px;
}

.kpi-left-content {
  font-size: 40px;
  font-weight: bold;
  /* overflow: auto; */
}

.kpi-right-content {
  font-size: 14px;
}

.kpi-left-content-progress {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  text-align: center;
}

.kpi-left-content-progress-number {
  font-size: 32px;
  font-weight: bold;
}

.kpi-left-content div div {
  vertical-align: middle;
}

/* .custom-scrollbar {
  // dimensions
  &::-webkit-scrollbar {
    width: 12px;
    height: 5px;
  }

  // track
  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: $box-grey;
  }

  // handle
  &::-webkit-scrollbar-thumb {
    background: $line-grey;
    border-radius: 4px;
  }

  // Handle on hover
  &::-webkit-scrollbar-thumb:hover {
    background: $primary-grey;
  }
} *//*# sourceMappingURL=Kpi.css.map */