@use "variables" as *;

// Typography
@font-face {
  font-family: "MarkForMC";
  src: url(../../public/fonts/MarkForMC.ttf);
}

// Bootstrap customization
@import "../../node_modules/bootstrap/scss/functions";

// Override default variables and mixins
$body-color: $text-black; // basic text color
$spacer: 2rem; // spacing basic unit
$hr-margin-y: $spacer * 3; // <hr> margin (my-5)
$hr-color: $line-grey; // <hr> color
$hr-border-color: $line-grey; // <hr> color
$hr-opacity: 1; // <hr> color
$font-family-base: MarkForMC; // font
$font-family-sans-serif: MarkForMC, system-ui, -apple-system, "Segoe UI", Roboto,
  "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: MarkForMC, SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;
$link-color: $primary-orange; // <a> tag
$link-shade-percentage: 15%; // <a> tag
$link-decoration: none; // <a> tag
$btn-border-radius: 100px; // .btn class pill shaped
$component-active-bg: $sidebar-grey; // sidebar
$nav-link-color: $text-black; // sidebar
$nav-link-hover-color: $primary-orange; // sidebar
$nav-link-font-size: 0.8rem; // sidebar
$nav-pills-link-active-color: $text-black; // sidebar
$input-border-color: $line-grey; // form border
$input-focus-bg: none; // form highlight on focus
$input-focus-border-color: none; // form highlight on focus
$input-focus-color: none; // form highlight on focus
$input-focus-width: 0; // form highlight on focus
$input-focus-box-shadow: none; // form highlight on focus
$input-btn-focus-box-shadow: none; // form highlight on focus
$form-select-indicator-color: $line-grey; // select chevron color
$form-select-padding-y: 1rem; // select padding
$input-group-addon-bg: $white; // icons in input group
$input-group-addon-border-color: rgba(
  $line-grey,
  1
); // border of icons in input group
$form-check-input-checked-bg-color: $primary-orange; // switch active bg
$form-switch-focus-color: rgba(black, 0.25); // switch button on focus
$form-check-input-focus-border: none; // switch on focus
$form-check-input-focus-box-shadow: none; // switch on focus
$modal-xl: 80%; // bigger modal

@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Generate custom colors components
$custom-colors: (
  "primary-orange": $primary-orange,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

@import "../../node_modules/bootstrap/scss/bootstrap";
