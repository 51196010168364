html,
body {
  background-color: #ffffff;
  margin: 0px;
  width: 100%;
  height: 100%;
}

main {
  background-color: #ffffff;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

p {
  font-size: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

.ml-auto {
  margin-left: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.w-80 {
  width: 80%;
}

.square {
  width: 14px;
  height: 14px;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.sticky.top-20 {
  top: 20px;
}

.sticky.top-1rem {
  top: 1rem;
}

.white-link {
  color: #ffffff;
  cursor: pointer;
}

.white-link:hover {
  text-decoration: underline;
}/*# sourceMappingURL=App.css.map */