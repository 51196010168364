.switcher-container {
  width: -moz-fit-content;
  width: fit-content;
  border: 2px solid #f28c00;
  border-radius: 99px;
  padding: 4px;
}

.switcher-button {
  background-color: #ffffff;
  color: #f28c00;
  cursor: pointer;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 99px;
  min-width: 180px;
  text-align: center;
  transition: all 0.3s;
}

.switcher-button:hover {
  background-color: rgba(248, 197, 128, 0.2);
}

.switcher-button.active {
  background-color: #f28c00;
  color: #ffffff;
}/*# sourceMappingURL=Switcher.css.map */