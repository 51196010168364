.noborder-tr {
  border: none !important;
  border-color: transparent !important;
}

.border-td {
  border-right: solid 1px #cccccc !important;
}

.deep-dive-kpi {
  min-height: 136px;
}

.deep-dive-kpi .row {
  width: 100%;
}/*# sourceMappingURL=TableDeepDive.css.map */