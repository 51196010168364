@use "../../styles/variables" as *;

.noborder-tr {
  border: none !important;
  border-color: transparent !important;
}

.border-td {
  border-right: solid 1px $line-grey !important;
}

.legend-td {
  vertical-align: middle;
}

.legend-td div {
  margin: 0 auto;
}
