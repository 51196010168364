@use "../../styles/variables" as *;

.page-login {
  background-color: $primary-grey;
  min-height: 100vh;
}

.container-login {
  width: 50%;
  max-width: 400px;
}

.logo-login {
  width: 100%;
  max-width: 200px;
}

.container-btn {
  min-height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.condition-list li {
  color: $white;
  opacity: 0.5;
}

.condition-list li.active {
  opacity: 1;
}

.text-tnc {
  color: $white;
  font-size: 0.8rem;
}
