@use "../../styles/variables" as *;

.container-sidebar {
  background-color: $sidebar-grey;
  min-height: 100vh;
  box-shadow: $soft-shadow;
}

.nav-link.active {
  text-decoration: underline;
}

.no-hover:hover {
  color: $text-black !important;
}

.sidebar-hamburger {
  font-size: 1.5rem;
  color: $text-black;
}

.sidebar-icon-link {
  width: 40px;
}

.sidebar-icon-link:hover .sidebar-icon-container {
  background-color: $chart-grey;
}

.sidebar-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.sidebar-icon {
  width: 80%;
}

.nav-link.active .sidebar-icon-text div div {
  text-decoration: underline;
}

.nav-link.active .sidebar-icon-container {
  background-color: $chart-grey;
}
