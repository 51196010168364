@use "../../styles/variables" as *;

.text-404 {
  color: $white;
}

.text-404 h1 {
  font-size: 180px;
  font-weight: bold;
}
