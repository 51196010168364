@use "../../styles/variables" as *;

.icon-group {
  color: $line-grey !important;
  border-right: 0 !important;
}

.bl-0 {
  border-left: 0 !important;
}
