@use "../../styles/variables" as *;

.noborder-tr {
  border: none !important;
  border-color: transparent !important;
}

.border-td {
  border-right: solid 1px $line-grey !important;
}

.deep-dive-kpi {
  min-height: 136px;
}

.deep-dive-kpi .row {
  width: 100%;
}
