@use "../../styles/variables" as *;

.filter-box-single {
  background-color: $box-grey;
  border-radius: 4px;
  z-index: 10;
  padding: 1rem;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  
}