@use "../../styles/variables" as *;

// override default
.react-tooltip {
  max-width: 400px;
  z-index: 100;
}

// custom
.react-tooltip span {
  font-weight: bold;
}

.tooltip-container {
  background-color: $white;
  color: $text-black;
  box-shadow: $soft-shadow;
}
