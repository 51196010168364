.time-period-slider {
  width: 100%;
  height: 10px;
}

.time-period-track {
  height: 10px;
  border-radius: 99px;
}

.time-period-track-0 {
  background-color: #e1e1e1;
}

.time-period-track-1 {
  background-color: #f28c00;
}

.time-period-track-2 {
  background-color: #e1e1e1;
}

.time-period-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  cursor: grab;
  top: -5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  text-align: center;
}

.time-period-mark {
  /* width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: $white;
  top: 2px; */
  width: 2px;
  height: 8px;
  background-color: #cccccc;
  top: 16px;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
}

.time-period-moving-label {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: -moz-max-content;
  width: max-content;
}/*# sourceMappingURL=TimePeriod.css.map */