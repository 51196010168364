.refreshing-container {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  background-color: #f28c00;
  border-radius: 99px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: #ffffff;
  text-align: center;
}

.refreshing-container.ml-expanded {
  margin-left: 130px;
}

.refreshing-container.ml-not-expanded {
  margin-left: 20px;
}/*# sourceMappingURL=Refreshing.css.map */