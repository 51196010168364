@use "../../styles/variables" as *;

.time-period-slider {
  width: 100%;
  height: 10px;
}

.time-period-track {
  height: 10px;
  border-radius: 99px;
}

.time-period-track-0 {
  background-color: $chart-grey;
}

.time-period-track-1 {
  background-color: $primary-orange;
}

.time-period-track-2 {
  background-color: $chart-grey;
}

.time-period-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $white;
  cursor: grab;
  top: -5px;
  box-shadow: $soft-shadow;
  text-align: center;
}

.time-period-mark {
  // style option 1
  /* width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: $white;
  top: 2px; */

  // style option 2
  width: 2px;
  height: 8px;
  background-color: $line-grey;
  top: 16px;

  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
}

.time-period-moving-label {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
}
