@use "../../styles/variables" as *;

.container-alert {
  position: fixed;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  transition: ease-in-out 0.5s !important;
}

.container-alert.pl-expanded {
  padding-left: 260px !important; //sidebar
}

.container-alert.pl-not-expanded {
  padding-left: 80px !important; //sidebar
}

.alert-shadow {
  box-shadow: $hover-shadow;
}
